import { MediaItemType } from '@types';
import cx from 'classnames';

import Image from '../Image';
import ResponsiveVideo from '../ResponsiveVideo';
import styles from './styles.module.scss';

const Media: React.FC<{
  className?: string;
  source: MediaItemType;
  sizes: string;
  autoPlay?: boolean;
}> = ({ className, source, sizes, autoPlay }) => {
  return (
    <figure className={cx(className, styles.wrap)}>
      {source?.type === 'image' && source.image && (
        <Image
          src={source.image}
          alt={source.image?.alt}
          sizes={sizes}
          objectFit="cover"
        />
      )}
      {source?.type === 'video' && (
        <ResponsiveVideo
          autoPlay={autoPlay}
          coverSizes={sizes}
          src={source.responsiveVideo}
          variant={source.controls ? 'videoPlayer' : 'backgroundVideo'}
        />
      )}
    </figure>
  );
};

export default Media;
