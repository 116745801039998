import Digits from '@components/atoms/Digits';
import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import Button from '@components/Button';
import Image from '@components/website/Image';
import MediaGallery from '@components/website/MediaGallery';
import PortableText from '@components/website/PortableText';
import { BREAKPOINT_STANDARD, BREAKPOINT_TABLET } from '@constants';
import useBounds from '@hooks/useBounds';
import {
  CtaButton as CtaButtonType,
  PageCustomerStory,
  PortableText as PortableTextType
} from '@sanity/sanity.types';
import cx from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useMedia } from 'react-use';
import type { SanityKeyed } from 'sanity-codegen';

import {
  AutostoreCardType,
  BigGalleryType,
  LeftMediaType,
  ListItemType,
  MediaItemType,
  RightMediaType
} from '../../../../types';
import { Column, Columns } from '../../Columns';
import Media from '../../Media';
import styles from './styles.module.scss';

type CTAButton = Omit<CtaButtonType, '_type'>;

export const MediumTitle = ({ text }: { text: string }) => (
  <Text as="div" variant="label" color="grey">
    {text}
  </Text>
);
export const LeftMedia = ({ media }: LeftMediaType) => {
  return (
    <div className={styles.mediaWrap}>
      <Media
        className={styles.media}
        source={media}
        autoPlay
        sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
      />
    </div>
  );
};

export const RightMedia = ({ media }: RightMediaType) => {
  return (
    <div className={styles.mediaWrap}>
      <Media
        className={styles.media}
        source={media}
        autoPlay
        sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
      />
    </div>
  );
};
export const LeftDigits = ({ text }: { text: string }) => (
  <Digits as="span">{text}</Digits>
);

export const AutostoreCard = ({ body, link }: AutostoreCardType) => (
  <Link href={'https://www.autostoresystem.com/'}>
    <div className={styles.autostoreCard}>
      <div>
        <Icon name="createdByAutostore" size="s" fill="navy" />
      </div>

      <Text
        className={styles.autostoreMiddleColumn}
        as="p"
        variant="b2"
        color="navy"
      >
        {body}
      </Text>
      <div>
        <Button variant="link" type="button">
          {link.label}
        </Button>
      </div>
    </div>
  </Link>
);

interface EditorialProps {
  header?: string;
  description?: string;
  richDescription?: PortableTextType;
  CTA?: CTAButton;
  centerAlign?: boolean;
}
export const Editorial = ({
  header,
  description,
  richDescription,
  CTA,
  centerAlign
}: EditorialProps) => {
  return (
    <div className={cx(styles.editorial, centerAlign && styles.centerAlign)}>
      {header && (
        <Title as="h2" variant="h3">
          {header}
        </Title>
      )}
      {description && (
        <Text variant="b1" color="grey5">
          {description}
        </Text>
      )}
      {richDescription && (
        <Columns className={styles.portableGrid}>
          <Column start={1} span={5}>
            <div className={styles.portableOverrides}>
              <PortableText body={richDescription} />
            </div>
          </Column>
        </Columns>
      )}
      {CTA && (
        <Button variant="smallMain" {...CTA}>
          {CTA?.label}
        </Button>
      )}
    </div>
  );
};

interface QuoteProps {
  quotation: string;
  name?: string;
  position?: string;
  CTA?: CTAButton;
}
export const Quote = ({ quotation, name, position, CTA }: QuoteProps) => {
  return (
    <div className={styles.editorial}>
      <Title
        as="h2"
        variant={quotation.length >= 30 ? 'blockquote' : 'h3'}
        className={styles.quote}
      >
        {quotation}
      </Title>
      {(name || position) && (
        <div>
          {name && (
            <Text variant="b2" color="grey5">
              {name}
            </Text>
          )}
          {position && (
            <Text variant="b2" color="grey5">
              {position}
            </Text>
          )}
        </div>
      )}

      {CTA && (
        <Button variant="smallMain" {...CTA}>
          {CTA?.label}
        </Button>
      )}
    </div>
  );
};

interface AutostoreCardAndMediaProps {
  autostoreCard: AutostoreCardType;
  media: MediaItemType;
}

export const AutostoreCardAndMedia = ({
  autostoreCard,
  media
}: AutostoreCardAndMediaProps) => {
  return (
    <Columns className={cx(styles.cardAndMediaWrap, styles.columnPadding)}>
      <Column start={1} span={4}>
        <div>
          <AutostoreCard {...autostoreCard} />
        </div>
      </Column>
      <Column start={6} span={7} className={styles.twoMediaWrap}>
        <Media
          className={styles.media}
          source={media}
          autoPlay
          sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
        />
      </Column>
    </Columns>
  );
};

interface TwoMediaProps {
  leftMedia: MediaItemType;
  rightMedia: MediaItemType;
}
export const TwoMedia = ({ leftMedia, rightMedia }: TwoMediaProps) => {
  return (
    <>
      {/* Mobile */}
      <MediaGallery
        className={cx(styles.twoMedia, styles.columnPadding, styles.mobileOnly)}
        sources={[leftMedia, rightMedia]}
      />

      {/* Desktop */}
      <Columns
        className={cx(
          styles.twoMedia,
          styles.columnPadding,
          styles.desktopOnly
        )}
      >
        <Column start={1} span={4}>
          <Media
            className={styles.media}
            source={leftMedia}
            autoPlay
            sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
          />
        </Column>
        <Column start={6} span={7} className={styles.twoMediaWrap}>
          <Media
            className={styles.media}
            source={rightMedia}
            autoPlay
            sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
          />
        </Column>
      </Columns>
    </>
  );
};

interface OneMediaProps {
  media: MediaItemType;
}

export const OneMedia = ({ media }: OneMediaProps) => {
  return (
    <Columns className={cx(styles.oneMedia)}>
      <Column start={1} span={12} className={styles.oneMediaWrap}>
        <Media
          className={cx(
            styles.oneMediaContent,
            media?.controls && styles.oneMediaControls
          )}
          source={media}
          autoPlay
          sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
        />
      </Column>
    </Columns>
  );
};

interface CustomerStoriesProps {
  items: Array<SanityKeyed<PageCustomerStory>>;
}

export const CustomerStories = ({ items }: CustomerStoriesProps) => {
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const [emblaRef, embla] = useEmblaCarousel({
    containScroll: 'keepSnaps'
  });

  const scrollTo = useCallback(
    (index: number) => embla?.scrollTo(index),
    [embla]
  );

  return (
    <div ref={emblaRef} className={styles.customerStoriesWrap}>
      <ul>
        {items.map(({ _id, ...props }, index) => (
          <li
            key={`slide-${_id || index}`}
            className={styles.slide}
            onMouseEnter={() => !isNarrow && scrollTo(index)}
          >
            <div className={styles.carouselCard}>
              <StoryCard key={_id} {...props} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

type StoryCardProps = Omit<PageCustomerStory, '_id'> & {
  start?: number;
  span?: number;
};

const StoryCard = ({
  start,
  span,
  thumbnail,
  category,
  customer,
  blurb,
  comingSoon,
  slug
}: StoryCardProps) => {
  const [hasHover, setHasHover] = useState(false);
  const [contentRef, contentBounds] = useBounds();
  const [collapsedRef, collapsedBounds] = useBounds();
  const [expandedRef, expandedBounds] = useBounds();

  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_STANDARD}px)`, false);
  const isExpanded = hasHover && !isNarrow;

  const variants = {
    expanded: {
      height:
        contentBounds.height + expandedBounds.height - collapsedBounds.height,
      width: contentBounds.width + expandedBounds.width - collapsedBounds.width
    },
    collapsed: {
      height: 'auto',
      width: 'auto'
    }
  };

  const transition = { duration: 0.2 };

  return (
    <Column className={styles.storyCard} start={start} span={span}>
      <Image
        alt="thumbnail"
        className={styles.storyImage}
        src={thumbnail}
        sizes="100%"
        objectFit="cover"
      />

      <motion.div
        className={styles.storyContentWrap}
        onMouseLeave={() => setHasHover(false)}
        onMouseEnter={() => setHasHover(true)}
        variants={variants}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        transition={transition}
      >
        <div className={styles.storyContent} ref={contentRef}>
          <Text as="span" variant="label" color="grey5">
            {category}
          </Text>

          <div>
            <motion.div
              ref={collapsedRef}
              animate={{ opacity: hasHover || isNarrow ? 0 : 1 }}
              transition={transition}
            >
              <Text className={styles.storyCustomer} variant="b3">
                {customer}
              </Text>
            </motion.div>

            <motion.div
              ref={expandedRef}
              animate={{ opacity: hasHover || isNarrow ? 1 : 0 }}
              transition={transition}
              className={styles.storyHover}
            >
              <Text className={styles.storyBlurb} variant="b3">
                {blurb}
              </Text>

              {comingSoon ? (
                <Text
                  className={styles.storyLinkLabel}
                  as="span"
                  variant="label"
                  color="grey5"
                >
                  Coming Soon
                </Text>
              ) : (
                <Link href={`/customer-story/${slug?.current}`}>
                  <Text
                    className={styles.storyLinkLabel}
                    as="span"
                    variant="label"
                    color="teal"
                  >
                    See Story
                  </Text>
                </Link>
              )}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </Column>
  );
};

export const BigGallery = ({ sources }: BigGalleryType) => {
  return <MediaGallery className={styles.bigGallery} sources={sources} />;
};

interface ListProps {
  items: Array<ListItemType>;
}

export const List = ({ items }: ListProps) => {
  return (
    <ul className={styles.list}>
      {items.map(item => (
        <li className={styles.listItem} key={item.title}>
          <Title as="h2" variant="h4">
            {item.title}
          </Title>
          <Text as="p" variant="b2" color="grey5">
            {item.body}
          </Text>
        </li>
      ))}
    </ul>
  );
};
