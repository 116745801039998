import cx from 'classnames';

import styles from './styles.module.scss';

interface DigitsProps extends React.HTMLAttributes<HTMLSpanElement> {
  as?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Digits = ({
  children,
  className,
  as: Component = 'span',
  ...textProps
}: DigitsProps) => (
  <Component className={cx(styles.digits, className)} {...textProps}>
    {children}
  </Component>
);

export default Digits;
