import Link from '@components/atoms/Link';
import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import { PortableText } from '@portabletext/react';
import { PortableText as PortableTextType } from '@sanity/sanity.types';
import cx from 'classnames';

import Image from '../Image';
import styles from './PortableText.module.scss';

type PortableTextRendererProps = {
  body: PortableTextType | undefined;
};

const PortableTextRenderer: React.FC<PortableTextRendererProps> = ({
  body
}) => (
  <PortableText
    value={body ?? []}
    components={{
      types: {
        inlineImage: ({ value }) => {
          return (
            <Image
              src={value.asset._ref}
              alt={value.alt}
              className={styles.InlineImage}
            />
          );
        }
      },
      block: {
        h1: ({ children }) => (
          <Title as="h2" variant="h2" className={styles.title}>
            {children}
          </Title>
        ),
        h2: ({ children }) => (
          <Title
            as="h2"
            variant="h4"
            className={cx(styles.title, styles.uppercase)}
          >
            {children}
          </Title>
        ),
        label: ({ children }) => (
          <Text as="p" variant="label" color="grey5" className={styles.label}>
            {children}
          </Text>
        ),
        normal: ({ children }) => (
          <Text as="p" variant="b2" color="grey5" className={styles.text}>
            {children}
          </Text>
        ),
        large: ({ children }) => (
          <Text as="p" variant="b1" color="grey5" className={styles.text}>
            {children}
          </Text>
        )
      },
      list: {
        bullet: ({ children }) => (
          <ul className={cx(styles.list, styles.disc)}>{children}</ul>
        ),
        number: ({ children }) => (
          <ol className={cx(styles.list, styles.decimal)}>{children}</ol>
        )
      },
      listItem: {
        bullet: ({ children }) => (
          <li>
            <Text as="span" variant="b2" color="grey5" className={styles.text}>
              {children}
            </Text>
          </li>
        )
      },
      marks: {
        strong: ({ children }) => (
          <strong className={styles.bold}>{children}</strong>
        ),
        link: function LinkMarkSerializer(node) {
          const { hrefInternal, hrefExternal, href } = node.value;

          if (href) {
            return (
              <a
                className={styles.link}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {node.children}
              </a>
            );
          }

          const isExternal =
            hrefExternal?.includes('http') || hrefExternal?.includes('mailto');

          if (isExternal) {
            return (
              <a
                className={styles.link}
                href={hrefExternal}
                target="_blank"
                rel="noreferrer"
              >
                {node.children}
              </a>
            );
          } else {
            return (
              <Link className={styles.link} href={hrefExternal ?? hrefInternal}>
                {node.children}
              </Link>
            );
          }
        }
      }
    }}
  />
);

export default PortableTextRenderer;
