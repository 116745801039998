import { BREAKPOINT_TABLET } from '@constants';
import { MediaItemType } from '@types';
import cx from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import Media from '../Media';
import styles from './styles.module.scss';

const MediaGallery: React.FC<{
  className?: string;
  sources: MediaItemType[];
}> = ({ className, sources }) => {
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const [emblaRef, embla] = useEmblaCarousel({
    containScroll: 'keepSnaps'
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (index: number) => embla?.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div className={cx(className, styles.wrap)} ref={emblaRef}>
      <ul>
        {sources.map((source, index) => (
          <li
            key={`slide-${source?._key || index}`}
            className={styles.slide}
            onMouseEnter={() => !isNarrow && scrollTo(index)}
          >
            <Media
              className={styles.media}
              source={source}
              autoPlay={selectedIndex === index}
              sizes={`(min-width: ${BREAKPOINT_TABLET}px) 33vw, 100vw`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MediaGallery;
